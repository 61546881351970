<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="d-block pb-2 d-sm-flex justify-content-sm-between">
          <div class="d-flex flex-column mr-4 mb-2">
            <span v-show="isEmptyCategories" class="mb-2">
              <svg class="mx-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              {{ $t('categoryWithoutServices') }}</span>
            <span v-show="isEmptyServices" class="mb-2">
              <svg class="mx-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              {{ $t('serviceWithoutEmployees') }}
            </span>
          </div>
              
          <div class="d-md-flex flex-md-row">
            <Popover
              trigger="manual"
              placement="top"
              :content="$t('clickAddServiceCategory')"
              :popper-class="
                checkWhichPopover + ' font-weight-bold shadow-popover'
              "
              v-model="isPopoverVisible"
            >
              <base-button
                slot="reference"
                type="primary"
                class="mb-4 d-block mr-2 min-w-220"
                @click="goToFormView(null, 'category')"
              >
                <em class="tim-icons icon-simple-add pr-2"></em>
                {{ $tc("routes.newCategory") }}
              </base-button>
            </Popover>
            <Popover
              trigger="manual"
              placement="bottom"
              :content="$t('clickAddService')"
              :popper-class="
                checkWhichPopover + ' font-weight-bold shadow-popover'
              "
              v-model="isPopoverVisible"
            >
              <base-button
                slot="reference"
                type="primary"
                class="mb-4 d-block min-w-220"
                @click="goToFormView(null, 'service')"
              >
                <em class="tim-icons icon-simple-add pr-2"></em>
                {{ $tc("routes.servicesNew") }}
              </base-button>
            </Popover>
          </div>
        </div>

        <div v-for="category in serviceCategories" :key="category.id">
          <div
            class="category-row d-flex p-2 align-items-center justify-content-between border-top border-bottom"
            @click="category.isCollapsed = !category.isCollapsed; $event.stopPropagation();"
          >
            <div class="d-flex flex-column flex-sm-row">
              <span>
                <em class="tim-icons icon-molecule-40 pr-2"></em>
                <strong>{{ category.name }} </strong>
              </span>
              <em class="pl-3 pl-sm-1" :class="{'highlighted': category.services.length === 0}">
                ( {{ category.services.length }} 
                  {{ $tc("services", category.services.length !== 1? 2 : 1) }}
                )
                <svg v-if="category.services.length === 0 || category.services.some( service => service.number_of_employees === 0)" class="ml-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                  <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </em>
            </div
            >
            <div class="d-flex">
              <base-button
                @click="goToFormView(category.id, 'category')"
                link
                type="primary"
                class="d-flex shadow-none"
              >
                <em class="tim-icons icon-pencil pb-1 pr-2"></em>
                <span class="d-none d-sm-block">{{
                  $t("routes.manageCategory")
                }}</span>
              </base-button>
              <base-button
                link
                type="primary"
                class="pl-4 shadow-none"
              >
                <em
                  v-if="!category.isCollapsed"
                  class="tim-icons icon-minimal-up pb-1"
                ></em>
                <em v-else class="tim-icons icon-minimal-down pb-1"></em>
              </base-button>
            </div>
          </div>
          <div v-if="!category.isCollapsed">
            <div
              v-for="service in category.services"
              :key="service.id"
              class="ml-4 mx-sm-3 m-0 py-3 row border-top border-bottom"
            >
              <div class="col-12 px-0 row align-items-center justify-content-between">
                <div class="col image-name-container d-flex align-items-center">
                  <div class="set-order mr-2">
                    <base-button
                      type="primary"
                      v-if="idServiceOrder !== service.id"
                      @click="editOrder(service)"
                      block
                      class="button-order"
                    >
                      {{ service.order }}
                    </base-button>
                  
                    <div class="d-flex" v-else>
                      <base-input :label="`${$t('order')}`" v-model="newOrder" class="mr-2 input-order" inputType="number" min="0" :max="totalOfServices"></base-input>
                      <div class="d-flex flex-column justify-content-end">
                        <base-button link type="primary" class="d-flex shadow-none"  @click="changeOrder(service, category)" :disabled="newOrder === service.order || newOrder < 0 || newOrder > totalOfServices">
                            <em class="tim-icons icon-pencil"></em>
                        </base-button>
                        <base-button link  type="primary" class="d-flex shadow-none" @click="idServiceOrder= null" >
                          <em class="tim-icons icon-simple-remove"></em>
                        </base-button>
                      </div>
                    </div>
                  </div>

                  <img
                    v-if="service.mainPicture"
                    @error="replaceByDefault"
                    :src="service.mainPicture.url"
                    class="img-table d-none d-sm-block"
                    :alt="$t('alt.service')"
                    width="100%"
                    height="100%"
                  />
                  <img v-else src="@/assets/img/img-default.png" class="img-table d-none d-sm-block" :alt="$t('alt.service')" width="100%" height="100%" />
                  <strong class="pl-2 text-wrap">{{ service.name }}</strong>
                  <svg v-if="service.number_of_employees === 0" class="ml-1 d-md-none" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                    <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="col description-container d-none d-xl-block text-truncate">
                  {{ service.description }}
                </div>
                <div class="col p-0 price-container d-none d-md-block text-center"
                >
                  {{ service.price }} €
                </div>
                <div class="col p-0 time-container d-none d-md-block text-center">
                  {{ service.time }} min
                </div>
                <div class="col p-0 discount-container d-none d-xl-block text-center">
                  <span v-if="service.discount <= 0">{{
                    $t("noDiscount")
                  }}</span>
                  <span v-else
                    >{{ service.discount }}% {{ $t("discount") }}</span
                  >
                </div>
                <div class="col p-0 number-employee-container text-center d-none d-md-block" :class="{'highlighted' : service.number_of_employees === 0}">
                  <svg v-if="service.number_of_employees === 0" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                    <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  {{ service.number_of_employees }}
                  {{ $tc("employee", service.number_of_employees !== 1? 2 : 1) }}
                </div>
                <div class="col p-0 reminder-days-container d-none d-md-block text-center">
                  {{ service.reminder_days }}
                  {{ $tc('day', service.reminder_days !== 1? 2 : 1) }}
                </div>
                <div class="col buttons-container" >
                  <base-button
                    @click="goToFormView(service.id, 'service')"
                    link
                    type="primary"
                    class="d-flex shadow-none ml-auto"
                  >
                    <em class="tim-icons icon-pencil pb-1 pr-2"></em>
                    <span class="d-md-none d-xl-block">{{ $t("edit") }}</span>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <base-table
          v-if="serviceCategories.length === 0 || isSpinnerShow"
          :data="[1]"
          class="table-responsive-md"
        >
          <template>
            <td class="col-6" >
              <Spinner
                v-if="isSpinnerShow"
                parentClasses="justify-content-end"
              ></Spinner>
              <span v-else>

              {{$t('emptyTableText')}}
              </span>
            </td>
            <td class="col-6"></td>
          </template>
        </base-table>
      </card>
    </div>
  </div>
</template>

<script>
import { Card, BaseTable, Spinner, BaseInput, BaseButton } from '@/components/index';
import { mapGetters, mapMutations, mapState } from "vuex";
import { Popover } from "element-ui";
import defaultImage from "../../../assets/img/img-default.png";

export default {
  name: "ServicesList",
  data() {
    return {
      route: "/service-categories",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
      },
      serviceCategories: [],
      serviceCategoriesColumns: ["name", "description"],
      columnsSize: [2, null],
      isSpinnerShow: true,
      isPopoverVisible: false,
      isShowPopoverServiceWithoutEmployee: true,
      newOrder: null,
      idServiceOrder: null,
      isEmptyCategories: false,
      isEmptyServices: false
    };
  },
  computed: {
    ...mapState(["whichPopover", "defaultTitle"]),
    ...mapGetters(["getWhichPopover"]),
    totalOfServices(){
      let total=0;
      this.serviceCategories.forEach(category=>{
        total+= category.services.length;
      })
      return total
    }
  },
  methods: {
    async getItems(page) {
      let currentPage = page === null ? 1 : page;
      const route = `${this.route}?page=${currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.service_categories;
        data.forEach((category) => {
          category.isCollapsed = true;

          if(category.services.length > 0){ 
            category.services.forEach( service => this.setMainPicture(service))
          }
        });
        this.serviceCategories = data;
        this.checkCategoriesOrServicesEmpty();
        this.isSpinnerShow = false;
        this.setWhichPopover(this.$tc("serviceCategory", 2));
      }
    },
    goToFormView(itemId, itemClass) {
      let routeName = itemClass === "service" ? "services" : "servicesCategory";
      let route;
      if (itemId) {
        route = { name: `${routeName}Manage`, params: { id: +itemId } };
      } else {
        route = { name: `${routeName}New` };
      }
      this.$router.push(route);
    },
    checkWhichPopover() {
      setTimeout(() => {
        if (this.whichPopover === this.$tc("serviceCategory", 2)) {
          return "";
        } else {
          return "d-none";
        }
      }, 500);
    },
    setMainPicture(service){
      if(service.pictures.length > 0 ){
        
        let mainPicture = service.pictures.find(picture => parseInt(picture.type) === 2);
        service.mainPicture = mainPicture === undefined ? service.pictures[0] : mainPicture;
      }      
    },
    editOrder(service){
        this.idServiceOrder = service.id;
        this.newOrder = service.order;
    },
    async changeOrder(service, category){

      let serviceUpdated = new FormData() ;
      serviceUpdated.append("_method", "PUT");
      serviceUpdated.append("service_category_id", service.category.id);
      serviceUpdated.append("order", this.newOrder);
      const route = `/service/${service.id}`;
      let response = await this.axios.post(route, serviceUpdated);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
      }
      this.idServiceOrder= null;
      service.order = this.newOrder
      category.services.sort((a,b)=> Number(a.order)-Number(+b.order))
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
    checkCategoriesOrServicesEmpty() {
      this.isEmptyCategories = this.serviceCategories.filter( category => category.services.length === 0).length > 0;    
      
      this.isEmptyServices = this.serviceCategories.filter( category => category.services.some(x => x.number_of_employees === 0)).length > 0;
    },
    ...mapMutations(["setWhichPopover"]),
  },
  watch: {
    getWhichPopover: function () {
      this.isPopoverVisible =
        this.serviceCategories.length === 0 &&
        this.whichPopover === this.$tc("serviceCategory", 2);
    },
  },
  mounted() {
    this.getItems(null);
  },
  destroyed() {
    this.setWhichPopover(null);
  },
  metaInfo() {
    return { title: `${this.$tc('services', 0)} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    BaseTable,
    Spinner,
    BaseInput,
    BaseButton,
    Popover,
  },
};
</script>

<style lang="scss" scoped>
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 50%);
}
.img-table {
  width: 40px;
  height: 40px;
}
.category-row{
  cursor: pointer;
  &:hover{
    background-color: #f2f2f2;
  }
}
.set-order{
  max-width: 80px;
  .button-order{
    padding: 10px 15px;
    margin-right: 5px;
  }
  .btn-link{
    padding: 5px;
  }
}
.price-container {
  max-width: 70px;
}
.time-container {
  max-width: 85px;
}
.discount-container {
  max-width: 120px;
}
.number-employee-container {
  max-width: 135px;
}
.reminder-days-container {
  max-width: 80px;
}
.buttons-container {
  max-width: 120px;
}
.highlighted {
  color: red;
}
.min-w-220 {
  min-width: 220px;
}
@media(min-width: 768px) {
  .buttons-container {
    max-width: 50px;
  }
}
@media(min-width: 1200px) {
  .buttons-container {
    max-width: 110px;
  }
}
</style>

<style lang="scss">
.input-order{
  .form-control{
    padding: 0.5rem;
    text-align: center;
  }
}
</style>
